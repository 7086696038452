// import React, { Component } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import ReactLogo from '../assets/react.png';

// SPA Routes
import PageOne from './pages/Stuff';
import PageTwo from './pages/KeyLinks';

const finesse = {
  backgroundColor: '#232425',
  color: '#f0f0f0',
  padding: '1em'
}

const imageSize = {
  width: '100px',
  margin: '0'
}

// class Header extends Component {
class Header extends React.Component {
  render() {
    return(
      <Router>
        <div>
          <nav style={finesse}>
            <Link to="/">
              <img src={ReactLogo} style={imageSize}
                  alt="React Logo" title="React Logo" />
            </Link>
            <ul>
              <li>
                <Link to="/">Homepage</Link>
              </li>
              <br />
              <li>
                <Link to="/extra">More Information</Link>
              </li>
            </ul>
          </nav>

          <Route path="/" exact component={PageOne} />
          <Route path="/extra" component={PageTwo} />
        </div>
      </Router>
    );
  }
};

export default Header;