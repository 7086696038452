import React, { Component } from 'react';

const main = {
  margin: '0.7em',
  textDecoration: 'underline'
}

const grid = {
  color: '#61dafb',
  backgroundColor: '#232425',
  margin: '2em',
  padding: '0.8em'
}

const gridCenter = {
  color: '#fff',
  textDecoration: 'underline',
  textAlign: 'center',
  fontSize: '1.5em'
}

export default class KeyLinks extends Component {
  render() {
    return(
      <div>
        <h2 style={main}>Key Links</h2>
        <table style={grid}>
          <tr style={gridCenter}>
            <a href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Website" title="Website">Website</a>
          </tr>
          <br />
          <tr style={gridCenter}>
            <a href="https://reactjs.org/docs/getting-started.html"
              target="_blank"
              rel="noopener noreferrer"
              alt="Documentation" title="Documentation">Documentation</a>
          </tr>
          <br />
          <tr style={gridCenter}>
            <a href="https://reactjs.org/tutorial/tutorial.html"
              target="_blank"
              rel="noopener noreferrer"
              alt="Tutorial" title="Tutorial">Tutorial</a>
          </tr>
          <br />
          <tr style={gridCenter}>
            <a href="https://reactjs.org/blog/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Blog" title="Blog">Blog</a>
          </tr>
          <br />
          <tr style={gridCenter}>
            <a href="https://reactjs.org/community/support.html"
              target="_blank"
              rel="noopener noreferrer"
              alt="Community" title="Community">Community</a>
          </tr>
          <br />
          <tr style={gridCenter}>
            <a href="https://github.com/facebook/react/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Github" title="Github">Github</a>
          </tr>
        </table>
      </div>
    );
  }
};