import React from 'react';

import One from '../info/One';
import Two from '../info/Two';

const main = {
  margin: '0.7em',
  textDecoration: 'underline'
}

function Stuff() {
  return(
    <div>
      <h2 style={main}>Why React?</h2>
      <One />
      <Two />
    </div>
  )
}

export default Stuff;