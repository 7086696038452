import React from 'react';

const title = {
  color: '#61dafb',
  backgroundColor: '#232425',
  padding: '1em',
  textDecoratino: 'underline'
}

const body = {
  padding: '0.5em 2em'
}

export default function One() {
  return(
    <div>
      <h3 style={title}>Declarative</h3>
      <p style={body}>
        React makes it painless to create interactive UIs. Design simple views for each state in your application, and React will efficiently update and render just the right components when your data changes.
        <br />
        <br />
        Declarative views make your code more predictable and easier to debug.</p>
    </div>
  )
}