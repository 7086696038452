import React from 'react';

const lower = {
  backgroundColor: '#232425',
  padding: '2em',
  color: '#f0f0f0'
}

export default function Footer() {
  return (
    <div>
      <p style={lower}>Lead City Coding Academy &copy; All Right Reserved, 2019</p>
    </div>
  );
}
