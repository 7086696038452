import React from 'react';

import Header from './components/AppRouter';
import Footer from './components/Footer';

// temporary
// import PartOne from './components/pages/Stuff';
// import PartTwo from './components/pages/KeyLinks';

import './App.css';

function App() {
  return (
    <div>
      <Header />
      {/* 
      <PartOne />
      <PartTwo /> 
      */}
      <Footer />
    </div>
  );
}

export default App;
